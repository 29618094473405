<template>
  <div class="container bg-white">
      <div class="wrapper">
        <h5 class="top-heading-small text-center">Poznaj i zaakceptuj regulamin aplikacji</h5>
        <p>
        <br>
        Dane osobowe są przetwarzane: <br>
        w celu świadczenia usług związanych z prowadzeniem i obsługą konta w Portalu/Aplikacji
        Mobilnej – podstawą prawną przetwarzania jest niezbędność przetwarzania do wykonania umowy
        (art. 6 ust. 1 lit. b) RODO), a w zakresie danych podanych fakultatywnie – podstawą prawną
        przetwarzania jest zgoda (art. 6 ust. 1 lit. a) RODO);
        </p>
        <p>
        w celach analitycznych i statystycznych – podstawą prawną przetwarzania jest uzasadniony interes
        Administratora (art. 6 ust. 1 lit. f) RODO), polegający na prowadzeniu analiz aktywności
        Użytkowników w Portalu/Aplikacji Mobilnej i sposobu korzystania z konta, a także ich preferencji,
        w celu poprawy stosowanych funkcjonalności; w związku z uzasadnionym interesem dane mogą
        być także udostępniane podmiotom związanym z Administratorem w ww. celach, w tym w celu
        budowania profilów, które mogą być wykorzystywane do poprawy jakości usług oraz
        dopasowywania treści oferowanych w Portal/Aplikację Mobilną przez te podmioty; w przypadku
        wyrażenia odpowiedniej zgody.
        </p>
        <p>
        w celu ewentualnego ustalenia, dochodzenia lub obrony przed roszczeniami – podstawą prawną
        przetwarzania jest uzasadniony interes Administratora (art. 6 ust. 1 lit. f) RODO) polegający na
        ochronie swoich praw;
        </p>
        <p>
        w celach marketingowych Administratora oraz innych podmiotów – zasady przetwarzania danych
        osobowych w celach marketingowych zostały opisane w sekcji MARKETING.
        </p>
        <p>
        Administrator umożliwia logowanie się do konta w Portalu/Aplikacji Mobilnej za pośrednictwem
        portali społecznościowych (Facebook). Jeżeli Użytkownik wybierze tę formę logowania,
        Portal/Aplikacja Mobilna, po uprzednim wyrażeniu zgody przez Użytkownika, pobiorą z konta
        Użytkownika w ramach portalu społecznościowego jedynie dane niezbędne do rejestracji i obsługi
        konta. Jeżeli Użytkownik umieszcza w Portalu/Aplikacji Mobilnej jakiekolwiek dane osobowe
        innych osób (w tym ich imię i nazwisko, adres, numer telefonu lub adres e-mail), może to uczynić
        jedynie pod warunkiem nienaruszenia przepisów obowiązującego prawa i dóbr osobistych tych
        osób.
        </p>
        <div class="wrapper-link"><a :href="'https://' + plainUrl + '/rules'">czytaj więcej>></a></div>
      </div>
      <div class="wrapper-btn">
        <router-link to="/rejestracja">
          <button class="btn-black-full w-100 mb-3">Akceptuję Regulamin Bookme</button>
        </router-link>
<!--        <router-link to="/logowanie">-->
<!--          <button class="btn-black-full w-100 mb-3">Wychodzę</button>-->
<!--        </router-link>-->
      </div>
  </div>
</template>
<script>
export default {
  name: 'RegisterRules'
}
</script>
<style lang="scss" scoped>
  .wrapper {
    margin-top: 50px;
    margin-bottom: 50px;
    font-size: 13px;
    font-weight: 500;
    width: 100%;
    height: 80vh;
    overflow-y: scroll;
    &-link {
      display: flex;
      justify-content: flex-end;
    }
    &-btn {
      display: flex;
      flex-direction: column;
      position: relative;
      bottom: 0px;
    }
  }
</style>
